

























import { Component, Vue, Watch } from 'vue-property-decorator'
import Header from './Header.vue'
import Sidebar from './Sidebar.vue'
@Component({
  name: 'layoutIndex',
  components: {
    Header,
    Sidebar,
  },
})
export default class Index extends Vue {
  private flag = true
  private reload() {
    this.flag = false
    this.$nextTick(() => {
      this.flag = true
    })
  }

  sidebarMenu: any = []
  get showSidebar() {
    return this.$store.getters?.showSidebar
  }

  get IsLoginRoute() {
    return this.$route.path?.indexOf('/login') > -1
  }
}
